let colourCount = 0;

const colours = [
    
    // "#0A942F", // Green
    // "#3357FF", // Blue
    // "#FF8C33", // Orange
    // "#8C33FF", // Purple
    // "#C2BF04", // Yellow
    // "#10CC6E", // Light Green
    // "#3399FF", // Light Blue
    // "#FF9933", // Apricot
    // "#333333", // Dark Grey
    // "#7D0404"  // Maroon
    "#058728", //(Darker Green)
    "#DA70D6", //(Orchid)
    "#1A1A1A", //(Dark Charcoal)
    "#4A90E2", //(Sky Blue)
    "#FF9900", //(Darker Orange)
    "#6BBE45", //(Bright Lime Green)
    "#A05DBB", //(Lavender Purple)
    "#808080", //(Darker Grey)
    "#1ABC9C", //(Teal)
    "#FF6F61", //(Coral Red)
    

];

export const colourWheel = () => {
    colourCount += 1;
    if (colourCount >= colours.length) {
        colourCount = 0;
    }
    console.log("Colour count:", colourCount);  // Add this for debugging
    return colours[colourCount];
};
